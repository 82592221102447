import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Success = () => (
  <Layout>
    <SEO title="Success" />
    <div className="notFound-wrapper" style={{minHeight: '400px'}}>
      <h1>Thank you!</h1>
      <p>We've recieved your submission and we'll be in touch shortly.</p>
      {/* <Link to={window.history.back()}>Go Back</Link> */}
    </div>
  </Layout>
)

export default Success
